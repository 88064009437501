import axios from "axios";
import { BASE_API_URL } from "src/config";
import { getFilterQueryUrl, getRequestHeaders } from "./FinmoNetworkUtils";

export const errorMessage = (error) => ({
  message:
    error.response && error.response.data.message
      ? error.response.data.message
      : error.message,
});

export const getOrganization = async (orgId) => {
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(
    `${BASE_API_URL}/backoffice/organization/${orgId}`,
    { headers: requestHeaders }
  );
  return response.data;
};

export const getKyb = async (kybId) => {
  const requestHeaders = await getRequestHeaders();
  const response = await axios.get(`${BASE_API_URL}/backoffice/kyb/${kybId}`, {
    headers: requestHeaders,
  });
  return response.data;
};

export const getPayin = async (payinId, org_id, env = "production") => {
  try {
    const requestHeaders = await getRequestHeaders();
    requestHeaders["x-org-id"] = org_id;
    requestHeaders["x-env"] = env;
    const response = await axios.get(`${BASE_API_URL}/torc/payin/${payinId}`, {
      headers: requestHeaders,
    });
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const getPayout = async (payoutId, org_id, env = "production") => {
  try {
    const requestHeaders = await getRequestHeaders();
    requestHeaders["x-org-id"] = org_id;
    requestHeaders["x-env"] = env;
    const response = await axios.get(
      `${BASE_API_URL}/torc/payout/${payoutId}`,
      {
        headers: requestHeaders,
      }
    );
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const getFxConversion = async (
  fx_conversion_id,
  org_id,
  env = "production"
) => {
  try {
    const requestHeaders = await getRequestHeaders();
    requestHeaders["x-org-id"] = org_id;
    requestHeaders["x-env"] = env;
    const response = await axios.get(
      `${BASE_API_URL}/torc/fx-conversion/${fx_conversion_id}`,
      {
        headers: requestHeaders,
      }
    );
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const getCheckout = async (checkoutId, org_id) => {
  try {
    const requestHeaders = await getRequestHeaders();
    requestHeaders["x-org-id"] = org_id;
    const response = await axios.get(
      `${BASE_API_URL}/torc/checkout/${checkoutId}`,
      { headers: requestHeaders }
    );
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const getWallet = async (walletId, org_id) => {
  try {
    const requestHeaders = await getRequestHeaders();
    requestHeaders["x-org-id"] = org_id;
    const response = await axios.get(
      `${BASE_API_URL}/torc/wallet/${walletId}`,
      { headers: requestHeaders }
    );
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const getCustomer = async (customerId, org_id, filterQueryArr = []) => {
  try {
    let url = `${BASE_API_URL}/torc/customer/${customerId}`;
    if (filterQueryArr.length > 0) {
      const filterUrl = getFilterQueryUrl(filterQueryArr);
      if (filterUrl) {
        url = `${url}?${filterUrl}`;
      }
    }
    const requestHeaders = await getRequestHeaders();
    requestHeaders["x-org-id"] = org_id;
    const response = await axios.get(url, { headers: requestHeaders });
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const getOrgUser = async (org_id, org_user_id) => {
  try {
    const requestHeaders = await getRequestHeaders();
    const response = await axios.get(
      `${BASE_API_URL}/backoffice/organization/${org_id}/user/${org_user_id}`,
      { headers: requestHeaders }
    );
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const getConnector = async (connectorId) => {
  try {
    const requestHeaders = await getRequestHeaders();
    const response = await axios.get(
      `${BASE_API_URL}/torc/connector/${connectorId}`,
      { headers: requestHeaders }
    );
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const getVirtualAcc = async (
  virtualAccId,
  org_id,
  filterQueryArr = []
) => {
  try {
    let url = `${BASE_API_URL}/torc/virtual-account/${virtualAccId}`;
    if (filterQueryArr.length > 0) {
      const filterUrl = getFilterQueryUrl(filterQueryArr);
      if (filterUrl) {
        url = `${url}?${filterUrl}`;
      }
    }
    const requestHeaders = await getRequestHeaders();
    requestHeaders["x-org-id"] = org_id;
    const response = await axios.get(url, { headers: requestHeaders });
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const getPayinMethod = async (payinMethodId) => {
  try {
    const requestHeaders = await getRequestHeaders();
    const response = await axios.get(
      `${BASE_API_URL}/torc/payin-method/${payinMethodId}`,
      { headers: requestHeaders }
    );
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const updateWebHookUrl = async (env, webhook_url) => {
  try {
    const requestHeaders = await getRequestHeaders();
    const response = await axios.patch(
      `${BASE_API_URL}/account/webhookUrl`,
      {
        env: env === "live" ? "prod" : env,
        webhook_url,
      },
      { headers: requestHeaders }
    );
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const createApiKey = async (env) => {
  try {
    const requestHeaders = await getRequestHeaders();
    const response = await axios.post(
      `${BASE_API_URL}/account/apiKey`,
      {
        env: env === "live" ? "prod" : env,
      },
      { headers: requestHeaders }
    );
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const getBankAccountDetails = async () => {
  try {
    const requestHeaders = await getRequestHeaders();
    const response = await axios.get(`${BASE_API_URL}/kyb/account-details`, {
      headers: requestHeaders,
    });
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

// fetching account summary

export const getAccountSummary = () => {
  const key = window.localStorage.getItem("test_api_key");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${key}`,
    },
  };

  axios
    .get("https://api.pay-now.io/api/overview/account-summary", config)
    .then((res) => {
      return res.data.data;
    })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateIpListing = async (iplist) => {
  try {
    const requestHeaders = await getRequestHeaders();
    const response = await axios.patch(`${BASE_API_URL}/account`, iplist, {
      headers: requestHeaders,
    });
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const updateLogo = async (url) => {
  try {
    const requestHeaders = await getRequestHeaders();
    const response = await axios.patch(`${BASE_API_URL}/account`, url, {
      headers: requestHeaders,
    });
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const updatePaymentInst = async (paymentinst) => {
  try {
    const requestHeaders = await getRequestHeaders();
    const response = await axios.patch(`${BASE_API_URL}/account`, paymentinst, {
      headers: requestHeaders,
    });
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const updateBankDetails = async (query, kyb_id) => {
  try {
    const requestHeaders = await getRequestHeaders();
    const response = await axios.patch(
      `${BASE_API_URL}/kyb`,
      {
        query: {
          kyb_id,
          bank_name: query.bank_name,
          bank_account_holder_name: query.bank_account_holder_name,
          bank_account_number: query.bank_account_number,
        },
      },
      { headers: requestHeaders }
    );
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};
