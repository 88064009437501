export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

export const accessToken = window.localStorage.getItem("access_token") || "";
export const test_api_key = window.localStorage.getItem("test_api_key") || "";
export const prod_api_key = window.localStorage.getItem("prod_api_key") || "";
export const BASE_API_URL = "https://bo-gtwy.qafinmo.net";
export const WEB_GTWY_API_URL = "https://bo-gtwy.qafinmo.net";

// export const BASE_API_URL = "http://localhost:3701";
// export const WEB_GTWY_API_URL = "http://localhost:3701";

export const TOKENIZATION_ANCHOR =
  "LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0KTUlJQ0lqQU5CZ2txaGtpRzl3MEJBUUVGQUFPQ0FnOEFNSUlDQ2dLQ0FnRUEwcW9pZWNnRVVrVGdEOTd2bkMzQwo5bVVpbmEzTldKS2o5ZGZLaDhnWTBzYXJnOGl0c2tCQlBPYTIwWG84UFVuSjFGZUZibWRrS1ZaelF0eGZpQ3VJClFNMHhmNHN2V21Lc1VGSEMvcTVxVXIwSy9rellHb090Z2VZa0l4MEtWNlJRcmdaRlJNOEtsSHMyNWNMby8rdmYKL1R4dERRUTVlRkdUYm9mUXFPY0JkS2FSNEV1OFVMRENNenlsR0ZGcDFQNnZ6N01jdlczaTFUUjN1ODhYcUFTcwpOMDNpSDdkUE94YUp1OVJhenhlZWxmTzNPYll3ZjNVZ3g3RU5ibFlrc3VIMWJPd0NGQW1ndXc4cGJoRy9jTW0zCjdqUHQvOGRIbzVUbG03UGhwU1lhcDZOMVBGaC9pd0ZYTjY2dmR2djFPaG1UdTU2ZjYxYW12N3RyakVSeWhkMXAKYjJETlczSjhDUVZYUzFWMGNYZG5UYTRUNHpGL1crMk1oa3picUNQVDdPVzUzVG11d3lFRHFZZzV2M0Z2QVZpegovTnlhR1M2LzA5UG41Rm90NlVvMmJuVTZHd2FNdG9UYlNaVXhSL0ZJOTNTT0o2TlJ0VkFkY0JUNkJJcUsraXYrCjVJWWcrcWJhUnFnSnBBUFlMTlYwdnJJeGR5VVIvcFNONkRVeitQY0NEc2lZL1o3SUtScTBBVWtLdEQzdFRPc0wKTjJHa2loRnplaVhJaDZYMFdmY1BScjBLb1Z0TkJEVUpMTnZ6eWZHbDQ2ZkZkRnlPSGVIbG4wSmJMNGo1V29CVQpxdS9saTc1dW4wSEdFNTExZ2xBTEZQNk0yeXl6SjZ4SU9DT0JZUnNjM3Z2WTRJaEk4dlcrVjNuSC90RmhaVnJPClpCK1Z4bk9YSDVuUmtSTFM5TkYwNEEwQ0F3RUFBUT09Ci0tLS0tRU5EIFBVQkxJQyBLRVktLS0tLQo=";
